// AppContext.tsx
import React, { createContext, useReducer, useContext } from "react";
import actions from "./actions";

// Define your initial state
interface AppState {
  // Your initial state properties here
  source_id: null;
}

const initialState: AppState = {
  // Initial state values here
  source_id: null,
};

type AppAction = { type: string; payload: any };

// Create your context with a default value
const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => {},
});

const reducer = (state: AppState, action: AppAction) => {
  // Reducer logic here
  switch (action.type) {
    case actions.STORE_SOURCE:
      return { ...state, source_id: action.payload };
    default:
      return state;
  }
};

const AppProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};

export { AppProvider, useAppContext, reducer, initialState };
