import React, { useEffect, useRef } from "react";
import finalogo from "../../../../../assets/finallogo.png";

const InteractiveParticleCircle: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const canvas: any = canvasRef.current;
    const container: any = containerRef.current;

    if (!canvas || !container) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Set canvas dimensions to match the container
    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;

    const particles: any[] = [];
    const initialParticleCount = 500;
    const radius = canvas.width / 2.32;
    const innerRadius = radius * 0.8;
    const hoverDistance = 80;
    const speedReductionFactor = 0.98;
    const colors = ["#0073CF", "#9CCF40", "#42CF9E"];
    const offsetY = -40;

    // Function to create a new particle
    function createParticle() {
      const angle = Math.random() * Math.PI * 2;
      const distance = Math.random() * (radius - innerRadius) + innerRadius;
      return {
        angle,
        distance,
        x: Math.cos(angle) * distance + canvas.width / 2,
        y: Math.sin(angle) * distance + canvas.height / 2,
        vx: 0,
        vy: 0,
        jitterX: (Math.random() - 0.5) * 0.1,
        jitterY: (Math.random() - 0.5) * 0.1,
        size: Math.random() * 3 + 1,
        color: colors[Math.floor(Math.random() * colors.length)],
        isMovingRandomly: Math.random() > 0.5,
      };
    }

    // Initialize particles
    for (let i = 0; i < initialParticleCount; i++) {
      particles.push(createParticle());
    }

    // Function to draw particles
    function drawParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      particles.forEach((particle) => {
        if (particle.isMovingRandomly) {
          particle.x += particle.jitterX;
          particle.y += particle.jitterY;

          const dx = particle.x - canvas.width / 2;
          const dy = particle.y - canvas.height / 2;
          const dist = Math.sqrt(dx * dx + dy * dy);

          if (dist > radius * 0.5) {
            particle.isMovingRandomly = false;
          }
        } else {
          particle.angle += 0.01;
          particle.x =
            Math.cos(particle.angle) * particle.distance + canvas.width / 2;
          particle.y =
            Math.sin(particle.angle) * particle.distance +
            canvas.height / 2 +
            offsetY;

          if (Math.random() < 0.005) {
            particle.isMovingRandomly = false;
          }
        }

        particle.x += particle.vx;
        particle.y += particle.vy;

        particle.vx *= speedReductionFactor;
        particle.vy *= speedReductionFactor;

        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();
      });
    }

    // Mouse hover effect
    canvas.addEventListener("mousemove", (e: any) => {
      const mouseX = e.offsetX;
      const mouseY = e.offsetY;

      particles.forEach((particle) => {
        const dx = particle.x - mouseX;
        const dy = particle.y - mouseY;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < hoverDistance) {
          particle.vx += dx / 50;
          particle.vy += dy / 50;
        }
      });
    });

    // Mouse click effect to add particles
    canvas.addEventListener("click", () => {
      for (let i = 0; i < 50; i++) {
        particles.push(createParticle());
      }
    });

    // Animation loop
    function animate() {
      drawParticles();
      requestAnimationFrame(animate);
    }

    animate();

    // Adjust canvas size on window resize
    function handleResize() {
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="relative w-[435px] h-[550px] flex justify-center items-center overflow-hidden"
    >
      <div
        className="absolute w-full h-full bg-center bg-contain bg-no-repeat"
        style={{ backgroundImage: `url(${finalogo})` }}
      ></div>
      <canvas ref={canvasRef} className="absolute top-0 left-0"></canvas>
    </div>
  );
};

export default InteractiveParticleCircle;
