"use client";

import { ColumnDef } from "@tanstack/react-table";

import {
  ArrowUpDown,
  DownloadIcon,
  EditIcon,
  InfoIcon,
  VideoIcon,
} from "lucide-react";
import CellWithNavigation from "./cellWithnavigation";
import { Button } from "../../../../../../components/button";
import { message, Popover, Spin } from "antd";
import axios from "axios";
import { APIStandards } from "../../../../../../lib/api_standards";
import { useRef, useState } from "react";
import GroundTruthButton from "./ground_truth_button";
import ReportButton from "./report_button";
import ApproveButton from "../approve_button";
import EditRemarks from "../edit_remarks";
import Connector from "../connector";

// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type Instance = {
  container_id: string;
  type: string;
  created_at: string;
  processed_at: string;
  elapsed_time: string;
  wagon_count: number;
  container_count: number;
  raw_container_count: number;
  raw_wagon_count: number;
  top_ground_truth: string;
  bot_ground_truth: string;
  report: string;
  isApproved: boolean;
  role: string;
  remarks: string;
};
export const columns: ColumnDef<Instance>[] = [
  {
    header: "Train ID",
    columns: [
      {
        accessorKey: "container_id",
        header: () => <div>id</div>,
        cell: ({ row }) => {
          const instance = row.original;
          return (
            <div className="flex items-center justify-center">
              <CellWithNavigation
                instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
              >
                {instance.container_id}
              </CellWithNavigation>
            </div>
          );
        },
      },
    ],
  },
  {
    header: "Type",
    columns: [
      {
        accessorKey: "type",
        header: () => <div>container/engine/others</div>,
        cell: ({ row }) => {
          const instance = row.original;
          return (
            <div className="flex items-center justify-center">
              <CellWithNavigation
                instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
              >
                {instance.type}
              </CellWithNavigation>
            </div>
          );
        },
      },
    ],
  },
  // {
  //   accessorKey: "timeline", // Add the new "Timeline" column
  //   header: () => <div>Timeline</div>,
  //   cell: ({ row }) => {
  //     const instance = row.original;
  //     // Function to determine if it's day or night
  //     const getDayOrNight = (createdAt: string | number | Date) => {
  //       const date = new Date(createdAt); // Parse the created_at value
  //       const hours = date.getHours(); // Get the hour (0-23)
  //       return hours >= 7 && hours <= 17 ? "Day" : "Night";
  //     };
  //     return (
  //       <CellWithNavigation
  //         instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
  //       >
  //         {getDayOrNight(instance.created_at)}
  //       </CellWithNavigation>
  //     );
  //   },
  // },
  {
    header: "Timeline",
    columns: [
      {
        accessorKey: "timeline",
        header: "Day/Night",
        cell: ({ row }) => {
          const instance = row.original;
          const getDayOrNight = (createdAt: string | number | Date) => {
            const date = new Date(createdAt); // Parse the created_at value
            const hours = date.getHours(); // Get the hour (0-23)
            return hours >= 7 && hours <= 17 ? "Day" : "Night";
          };
          return (
            <div className="flex items-center justify-center">
              <CellWithNavigation
                instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
              >
                {getDayOrNight(instance.created_at)}
              </CellWithNavigation>
            </div>
          );
        },
      },
    ],
  },
  {
    header: "Created At",
    columns: [
      {
        accessorKey: "created_at",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() => {
                const isSorted = column.getIsSorted();

                if (!isSorted) {
                  column.toggleSorting(true);
                } else if (isSorted === "desc") {
                  column.toggleSorting(false);
                } else {
                  column.toggleSorting(true);
                }
              }}
            >
              dd-mm-yyyy-hh-mm
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          const instance = row.original;
          const formatDate = (isoString: any) => {
            const date = new Date(isoString);
            const formattedDate = date.toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            return formattedDate;
          };
          return (
            <div className="flex items-center justify-center">
              <CellWithNavigation
                instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
              >
                {formatDate(instance.created_at)}
              </CellWithNavigation>
            </div>
          );
        },
      },
    ],
  },
  {
    header: "Processed At",
    columns: [
      {
        accessorKey: "processed_at",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() => {
                const isSorted = column.getIsSorted();

                if (!isSorted) {
                  column.toggleSorting(true);
                } else if (isSorted === "desc") {
                  column.toggleSorting(false);
                } else {
                  column.toggleSorting(true);
                }
              }}
            >
              dd-mm-yyyy-hh-mm
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          );
        },
        cell: ({ row }) => {
          const instance = row.original;
          const formatDate = (isoString: any) => {
            const date = new Date(isoString);
            const formattedDate = date.toLocaleString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            return formattedDate;
          };
          return (
            <div className="flex items-center justify-center">
              <CellWithNavigation
                instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
              >
                {instance.processed_at ? (
                  formatDate(instance.processed_at)
                ) : (
                  <div className="flex items-center text-center justify-center">
                    -
                  </div>
                )}
              </CellWithNavigation>
            </div>
          );
        },
      },
    ],
  },
  {
    header: "Elapsed Time",
    columns: [
      {
        accessorKey: "elapsed_time",
        header: () => <div>nos</div>,
        cell: ({ row }) => {
          const instance = row.original;
          const formatTime = (totalSeconds: any) => {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const remainingSeconds = totalSeconds % 60;

            let formattedTime = "";

            if (hours > 0) {
              formattedTime += `${hours}h`;
            }

            if (minutes > 0) {
              formattedTime += `${minutes}m`;
            }

            if (remainingSeconds > 0 || formattedTime === "") {
              formattedTime += `${remainingSeconds}s`;
            }

            return formattedTime;
          };
          return (
            <div className="flex items-center justify-center">
              <CellWithNavigation
                instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
              >
                {instance.elapsed_time ? (
                  formatTime(instance.elapsed_time)
                ) : (
                  <div className="flex items-center text-center justify-center">
                    -
                  </div>
                )}
              </CellWithNavigation>
            </div>
          );
        },
      },
    ],
  },
  // {
  //   accessorKey: "wagon_count",
  //   header: () => <div>Wagon Count</div>,
  //   cell: ({ row }) => {
  //     const instance = row.original;
  //     return (
  //       <CellWithNavigation
  //         instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
  //       >
  //         {instance.wagon_count}
  //       </CellWithNavigation>
  //     );
  //   },
  // },
  // {
  //   accessorKey: "container_count",
  //   header: () => <div>Container Count</div>,
  //   cell: ({ row }) => {
  //     const instance = row.original;
  //     return (
  //       <CellWithNavigation
  //         instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
  //       >
  //         {instance.container_count}
  //       </CellWithNavigation>
  //     );
  //   },
  // },
  {
    header: "Raw Count",
    columns: [
      {
        accessorKey: "raw_container_count",
        header: () => (
          <div className="flex flex-row gap-5 items-center justify-center">
            <div>Container</div> <div>Wagon</div>
          </div>
        ),
        cell: ({ row }) => {
          const instance = row.original;
          return (
            <div className="flex flex-row gap-8 items-center justify-center">
              <CellWithNavigation
                instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
              >
                {instance.raw_container_count}
              </CellWithNavigation>
              <CellWithNavigation
                instanceId={`${instance.container_id}/${instance.container_id}/${instance.container_id}`}
              >
                {instance.raw_wagon_count}
              </CellWithNavigation>
            </div>
          );
        },
      },
    ],
  },
  {
    header: "Ground Truth",
    columns: [
      {
        accessorKey: "ground_truth",
        header: () => (
          <div className="flex flex-row gap-5 items-center justify-center">
            <div>Container</div> <div>Wagon</div>
          </div>
        ),
        cell: ({ row }) => {
          const instance = row.original;

          return (
            <>
              {/* {instance.ground_truth ? (
              <Button
                onClick={() => {
                  Object.assign(document.createElement("a"), {
                    target: "_blank",
                    rel: "noopener noreferrer",
                    href: instance.ground_truth,
                  }).click();
                }}
              >
                <DownloadIcon />
              </Button>
            ) : (
              
            )} */}
              <GroundTruthButton instance={instance} />
            </>
          );
        },
      },
    ],
  },
  {
    header: "Report",
    columns: [
      {
        accessorKey: "report",
        header: () => <div>xlxs</div>,
        cell: ({ row }) => {
          const instance = row.original;
          return <ReportButton instance={instance} />;
        },
      },
    ],
  },
  ...(JSON.parse(sessionStorage.getItem("activeUser") || "{}") &&
  JSON.parse(sessionStorage.getItem("activeUser") || "{}").role === "admin"
    ? [
        {
          header: "Approval Status",
          accessorKey: "isApproved",
          cell: ({ row }: any) => {
            const instance = row.original;
            return (
              <div className="flex mx-auto items-center justify-center">
                <ApproveButton instance={instance} />
              </div>
            );
          },
        },
      ]
    : []),
  {
    header: "Remarks",
    columns: [
      {
        accessorKey: "message",
        header: () => <div>Remarks</div>,
        cell: ({ row }) => {
          const instance = row.original;
          return <EditRemarks instance={instance} />;
        },
      },
    ],
  },
  ...(JSON.parse(sessionStorage.getItem("activeUser") || "{}") &&
  JSON.parse(sessionStorage.getItem("activeUser") || "{}").role === "admin"
    ? [
        {
          header: "Actions",
          columns: [
            {
              accessorKey: "connect",
              header: () => <div>Connect</div>,
              cell: ({ row }: any) => {
                const instance = row.original;
                return (
                  <div className="flex items-center justify-center">
                    <Connector containerId={instance.container_id} />
                  </div>
                );
              },
            },
          ],
        },
      ]
    : []),
];
