import { Sliders } from "lucide-react";
import ParamsControl from "../components/param-control";
// import { Toast } from "../../../../../components/toaster";

export default function ParamScreen() {
  return (
    <div className="min-h-screen max-h-screen overflow-y-scroll p-4 bg-gray-100">
      <ParamsControl />
    </div>
  );
}
