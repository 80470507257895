"use client";

import { useState, useEffect } from "react";

import {
  Save,
  Loader2,
  Settings,
  Box,
  Train,
  Map,
  AlertTriangle,
} from "lucide-react";
import { Input } from "../../../../../components/input";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../../components/card";
import { Label } from "../../../../../components/label";
import { Button } from "../../../../../components/button";
// import { toast } from "./use-toast";
import { Switch } from "../../../../../components/Switch";
import { Slider } from "../../../../../components/slider";
import { message } from "antd";
import axios from "axios";
import { APIStandards } from "../../../../../lib/api_standards";

// Simulated function to fetch data from DB
const fetchInitialData = async () => {
  // In a real application, this would be an API call
  const res = await axios.get(APIStandards.ADMIN.GET_PARAMS);
  const data = res.data;

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        axle_params: {
          RemoveFalsePositivesForAxle: data["RemoveFalsePositivesForAxle"],
          AxleTimeDifferenceToRemoveFalsePositivesInSec:
            data["AxleTimeDifferenceToRemoveFalsePositivesInSec"],
          MinimumAxleCountToConsiderAsAxle:
            data["MinimumAxleCountToConsiderAsAxle"],
        },
        container_params: {
          RemoveFalsePositivesForContainers:
            data["RemoveFalsePositivesForContainers"],
          ContainerTimeDifferenceToRemoveFalsePositivesInSec:
            data["ContainerTimeDifferenceToRemoveFalsePositivesInSec"],
          ContainerTimeDifferenceIfTwoBoxProbabilityEqualInSec:
            data["ContainerTimeDifferenceIfTwoBoxProbabilityEqualInSec"],
          UseBoxProbabilityForDuplicateRemoval:
            data["UseBoxProbabilityForDuplicateRemoval"],
        },
        wagon_params: {
          WagonTimeDifferenceToConsiderAsSameWagonInSec:
            data["WagonTimeDifferenceToConsiderAsSameWagonInSec"],
          NoOfWagonsInTopToCheckForEngines:
            data["NoOfWagonsInTopToCheckForEngines"],
          NoOfWagonsInBottomToCheckForEngines:
            data["NoOfWagonsInBottomToCheckForEngines"],
          UseWagonBoxProbabilityToFindBestWagonNumber:
            data["UseWagonBoxProbabilityToFindBestWagonNumber"],
        },
        mapping_params: {
          TimeDifferenceBetweenWagonsToConsiderAsFalsePositiveInSec:
            data["TimeDifferenceBetweenWagonsToConsiderAsFalsePositiveInSec"],
          BufferTimeForFirstWagonInSec: data["BufferTimeForFirstWagonInSec"],
          PercentOfTimeToTakeFromConnectorForBufferInSec:
            data["PercentOfTimeToTakeFromConnectorForBufferInSec"],
          DoPostProcessingAfterMapping: data["DoPostProcessingAfterMapping"],
          ContainerTimeDifferenceToConsiderAsSameContainerInSec:
            data["ContainerTimeDifferenceToConsiderAsSameContainerInSec"],
          MergeRowsBasedOnSize: data["MergeRowsBasedOnSize"],
          TotalContainerSizeToMergeRows: data["TotalContainerSizeToMergeRows"],
        },
        error_handling_params: {
          NoOfTimesTheErrorShouldPrintInExcel:
            data["NoOfTimesTheErrorShouldPrintInExcel"],
        },
      });
    }, 1000);
  });
};

const ParamsControl = () => {
  const [params, setParams] = useState<any>({});
  const [initialParams, setInitialParams] = useState<any>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadInitialData = async () => {
      const data: any = await fetchInitialData();
      setParams(data);
      setInitialParams(data);
      setLoading(false);
    };
    loadInitialData();
  }, []);

  const handleChange = (category: any, key: any, value: any) => {
    setParams((prevParams: any) => ({
      ...prevParams,
      [category]: {
        ...prevParams[category],
        [key]: value,
      },
    }));
  };

  const renderControl = (category: any, key: any, value: any) => {
    if (typeof value === "boolean") {
      return (
        <Switch
          className="ml-4"
          checked={value}
          onCheckedChange={(checked) => handleChange(category, key, checked)}
        />
      );
    } else if (typeof value === "number") {
      return (
        <div className="space-y-2">
          <Slider
            min={0}
            max={Math.max(value * 2, 10)}
            step={0.1}
            value={[value]}
            onValueChange={([newValue]) =>
              handleChange(category, key, newValue)
            }
          />
          <Input
            type="number"
            value={value}
            onChange={(e) =>
              handleChange(category, key, parseFloat(e.target.value))
            }
            className="w-full"
          />
        </div>
      );
    } else {
      return (
        <Input
          type="text"
          value={value}
          onChange={(e) => handleChange(category, key, e.target.value)}
          className="w-full"
        />
      );
    }
  };

  const hasChanges = (category: any) => {
    return (
      JSON.stringify(params[category]) !==
      JSON.stringify(initialParams[category])
    );
  };

  const saveChanges = async (category: any) => {
    // In a real application, this would be an API call to save changes
    setInitialParams((prevParams: any) => ({
      ...prevParams,
      [category]: params[category],
    }));
    // toast({
    //   title: "Changes Saved",
    //   description: `${category.replace(
    //     /_/g,
    //     " "
    //   )} parameters have been updated.`,
    // });
    const res = await axios.put(
      APIStandards.ADMIN.UPDATE_PARAMS,
      params[category]
    );
    // console.log(params[category]);
    const data = res.data;
    if (data["status"]) {
      message.success(`${category}: changes saved!`);
    } else {
      message.error("Something went wrong!");
    }
  };

  const getCategoryIcon = (category: any) => {
    switch (category) {
      case "axle_params":
        return <Settings className="w-6 h-6" />;
      case "container_params":
        return <Box className="w-6 h-6" />;
      case "wagon_params":
        return <Train className="w-6 h-6" />;
      case "mapping_params":
        return <Map className="w-6 h-6" />;
      case "error_handling_params":
        return <AlertTriangle className="w-6 h-6" />;
      default:
        return <Settings className="w-6 h-6" />;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader2 className="w-8 h-8 animate-spin text-[#0A4378]" />
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <Button
        onClick={async () => {
          try {
            const res = await axios.post(APIStandards.ADMIN.RESET_PARAMS);
            const status = res.data["status"];
            if (status) {
              message.success("Parameters have been reset to default values.");
            } else {
              message.error("Something went wrong");
            }
            const data = await fetchInitialData();
            setParams(data);
            setInitialParams(data);
          } catch (error) {
            message.error("Failed to reset parameters.");
            console.error(error);
          }
        }}
        className="mt-6 w-full"
      >
        Reset to Default Values
      </Button>
      {Object.entries(params).map(([category, categoryParams]) => (
        <Card key={category} className="overflow-hidden">
          <CardHeader className="bg-[#0A4378] text-white">
            <CardTitle className="text-2xl font-bold capitalize flex items-center space-x-2">
              {getCategoryIcon(category)}
              <span>{category.replace(/_/g, " ")}</span>
            </CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <div className="space-y-6">
              {Object.entries(categoryParams as { [key: string]: any }).map(
                ([key, value]) => (
                  <div key={key} className="space-y-2">
                    <Label className="text-sm font-medium text-gray-700 capitalize">
                      {key.replace(/_/g, " ")}
                    </Label>
                    {renderControl(category, key, value)}
                  </div>
                )
              )}
            </div>
            <Button
              onClick={() => saveChanges(category)}
              className="mt-6 w-full"
              disabled={!hasChanges(category)}
            >
              <Save className="w-4 h-4 mr-2" />
              Save Changes
            </Button>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default ParamsControl;
