import { useEffect, useState } from "react";
import { DataTable } from "../components/table/data-table";
import { columns } from "../components/table/columns";
import { Button, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { format } from "date-fns";
import { RefreshCcw } from "lucide-react";
import { APIStandards } from "../../../../../lib/api_standards";

function InstanceList2() {
  const [InstanceList, setInstanceList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const pathname = useLocation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [refreshedTime, setLastRefreshedTime] = useState(0);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("activeUser") || "{}")
  );
  const fetchImagesStreaming = async () => {
    setIsLoading(true);
    setLastRefreshedTime(0);
    const date = format(selectedDate, "yyyy-MM-dd").trim();

    try {
      setInstanceList([]);
      // Use Fetch API to make a request to the streaming endpoint
      const response = await fetch(
        `${APIStandards.APP.GET_INSTANCES}?date=${date}&user_id=${userDetails.userID}`,
        {
          method: "GET",
        }
      );

      if (!response.body) {
        throw new Error("ReadableStream not supported or empty response body.");
      }

      // Process the stream using a TextDecoder
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      let done = false;
      let buffer = ""; // Buffer to accumulate partial chunks

      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;

        if (value) {
          // Decode the chunk and append it to the buffer
          buffer += decoder.decode(value, { stream: true });

          // Split buffer into complete JSON objects by newline
          const lines = buffer.split("\n");
          buffer = lines.pop()!; // Save incomplete JSON for the next chunk

          // Parse and append each complete JSON object
          const parsedData = lines.map((line) => JSON.parse(line));
          setInstanceList((prev) => [...prev, ...parsedData]);
          setIsLoading(false);
        }
      }

      // Handle any remaining data in the buffer (if it's a complete JSON object)
      if (buffer.trim()) {
        setInstanceList((prev) => [...prev, JSON.parse(buffer)]);
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    console.log(InstanceList);
  }, [InstanceList]);

  useEffect(() => {
    if (userDetails) {
      fetchImagesStreaming();

      // Reset refresh timer
      const timerId = setInterval(() => {
        setLastRefreshedTime((prevTime) => prevTime + 1);
      }, 1000);

      return () => clearInterval(timerId);
    }
  }, [selectedDate, userDetails]);

  return (
    <div
      className={` mx-4 py-10 ${
        pathname.pathname === "/admin/instances"
          ? "min-h-[100vh] max-h-[100vh] overflow-y-auto"
          : ""
      }`}
    >
      {isLoading && (
        <div className="flex mx-auto items-center min-h-[85vh] max-h-[85vh] justify-center">
          <Spin />
        </div>
      )}
      {!isLoading && (
        <div className={`flex flex-col gap-5 `}>
          <div className="flex flex-row justify-between">
            <div className=" text-3xl font-bold tracking-wide">
              Trains ({InstanceList.length})
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div>Last Refreshed {refreshedTime}s ago</div>
              <div>
                <Button
                  icon={<RefreshCcw />}
                  onClick={() => {
                    setInstanceList([]);
                    fetchImagesStreaming();
                  }}
                >
                  Refresh
                </Button>
              </div>
            </div>
          </div>
          <div>
            {" "}
            <DataTable
              columns={columns}
              data={InstanceList}
              setSelectedDate={setSelectedDate}
              selectedDate={selectedDate}
            />
          </div>
        </div>
      )}
      <footer
        style={{
          textAlign: "center",
          backgroundColor: "#ffffff",
          color: "gray-300",
          position: "absolute",
          bottom: "0",
          left: "50%",
          fontSize: "14px",
        }}
      >
        <p>
          Copyrights &copy; {new Date().getFullYear()} RoshAi. All Rights
          Reserved.
        </p>
      </footer>
    </div>
  );
}

export default InstanceList2;
