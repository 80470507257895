import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import bigroshAiLogo from "../../../../../assets/big-logo.png";
import loginbckground from "../../../../../assets/login_background.jpg";
import Login from "../components/login";
import InteractiveParticleCircle from "../components/InteractiveParticleLogo";

export default function LoginViewParticles() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState<any>(false);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("activeUser") || "{}")
  );

  useEffect(() => {
    if (userDetails.role !== "admin") {
      setIsAdmin(false);
    } else {
      setIsAdmin(true);
    }
  }, [userDetails]);

  return (
    <div
      className="h-screen flex flex-col lg:flex-row relative bg-cover bg-center"
      style={{
        backgroundImage: `url(${loginbckground})`,
      }}
    >
      {/* Logo */}
      <img
        className="h-16 w-32 absolute top-4 left-4 lg:h-20 lg:w-36"
        src={bigroshAiLogo}
        alt="Logo"
      />

      {/* Particle Animation */}
      <div className="absolute top-[40%] left-[10%] transform -translate-y-1/2 hidden md:block h-[200px] w-[200px] lg:h-[400px] lg:w-[400px]">
        <InteractiveParticleCircle />
      </div>

      {/* Login Form */}
      <div className="absolute top-[40%] right-[10%] h-full w-full lg:w-auto pr-6 sm:pr-20 lg:pr-40">
        <div className="bg-white shadow-lg rounded-lg p-6 border border-gray-300 w-[90%] sm:w-[400px] md:w-[500px] h-auto">
          <Login />
        </div>
      </div>

      {/* Footer */}
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 p-4 w-full text-center bg-transparent">
        <p className="text-xs md:text-sm text-gray-700">
          Copyrights © {new Date().getFullYear()} RoshAi. All Rights Reserved.
        </p>
      </div>
    </div>
  );
}
