import React, { useRef, useState } from "react";
import { Button, Modal, Spin, message } from "antd";
import axios from "axios";
import { APIStandards } from "../../../../../lib/api_standards";
import { useAppContext } from "../../../../../context/app_context";
import actions from "../../../../../context/actions";

interface ConnectorProps {
  containerId: string;
}

const Connector: React.FC<ConnectorProps> = ({ containerId }) => {
  const { state, dispatch } = useAppContext();
  const sourceRef = useRef<string | null>(null);
  const [targetId, setTargetId] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConnectClick = () => {
    const source_id = sessionStorage.getItem("source_id") || null;
    if (!source_id || source_id === "") {
      sessionStorage.setItem("source_id", containerId);
      message.info("Source row selected. Now select a target row.");
    } else if (!targetId) {
      setTargetId(containerId);
      setIsModalVisible(true);
    }
  };

  const handleConfirmConnection = async () => {
    const source_id = sessionStorage.getItem("source_id") || null;
    if (source_id && targetId) {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `${APIStandards.APP.MERGE_CONTAINER}?source_instance_id=${source_id}&target_instance_id=${targetId}`
        );
        const data = response.data;
        if (data["success"]) {
          message.success(`Rows connected: ${source_id} → ${targetId}`);
        } else {
          message.error("Failed to connect rows.");
        }
      } catch (error) {
        message.error("Failed to connect rows.");
      } finally {
        sessionStorage.setItem("source_id", "");
        setIsLoading(false);
        setTargetId(null);
        setIsModalVisible(false);
      }
    }
  };

  return (
    <>
      <Button onClick={handleConnectClick}>
        {sessionStorage.getItem("source_id") === containerId
          ? "Selected (Source)"
          : "Connect"}
      </Button>
      <Modal
        title="Confirm Connection"
        visible={isModalVisible}
        onOk={handleConfirmConnection}
        onCancel={() => setIsModalVisible(false)}
      >
        <Spin spinning={isLoading}>
          <p>
            Are you sure you want to connect rows with IDs:{" "}
            {sessionStorage.getItem("source_id")} → {targetId}?
          </p>
        </Spin>
      </Modal>
    </>
  );
};

export default Connector;
