const isDev = false
const devUrl = "http://localhost:8000"
const prodUrl = "https://roshai-coai-cvpro.azurewebsites.net"
const socketUrl = "ws://localhost:5678"
const serverUrl = isDev ? devUrl : prodUrl
export const APIStandards = {
    ADMIN: {
        CREATE_USER: `${serverUrl}/api/container_vision/create_user/`,
        LIST_USER: `${serverUrl}/api/container_vision/list_users/`,
        GET_PARAMS:`${serverUrl}/api/container_vision/get-parameter-settings/`,
        UPDATE_PARAMS:`${serverUrl}/api/container_vision/update-parameter-settings/`,
        RESET_PARAMS:`${serverUrl}/api/container_vision/reset-parameter/`

    },
    USER: {
        SOCKET_URL: socketUrl,
        LOGIN: `${serverUrl}/api/container_vision/login/`
    },
    APP: {
        GET_COUNTS: `${serverUrl}/api/container_vision/get_counts/?date=all`,
        GET_INSTANCES: `${serverUrl}/api/container_vision/instance/`,
        TEMP_STATS_HOURLY: `${serverUrl}/api/container_vision/temperature-stats-hourly`,
        TEMP_STATS_DAILY: `${serverUrl}/api/container_vision/temperature-stats`,
        TEMP_STATS_WEEKLY: `${serverUrl}/api/container_vision/temperature-stats-weekly`,
        TEMP_STATS_MONTHLY: `${serverUrl}/api/container_vision/temperature-stats-monthly`,
        GET_RADAR_DATA: `${serverUrl}/api/container_vision/get_radar_data`,
        LIST_COLLECTED_IMAGES: `${serverUrl}/api/container_vision/list_collected_images`,
        TRANSCRIBE: `${serverUrl}/api/container_vision/transcribe/`,
        GET_PREDICTION: `${serverUrl}/api/container_vision/prediction`,
        GENERATE_VIDEO: `${serverUrl}/api/container_vision/get_video_feed/`,
        GENERATE_VIDEO_TOP: `${serverUrl}/api/container_vision/get_video_feed_top_cam/`,
        GENERATE_VIDEO_BOTTOM: `${serverUrl}/api/container_vision/get_video_feed_bottom_cam/`,
        GET_OVERVIEW_DATA: `${serverUrl}/api/container_vision/get_overview_data/`,
        GET_BYTRAIN_OVERVIEW_DATA: `${serverUrl}/api/container_vision/get_bytrain_overview_data/`,
        GET_TRAIN_REPORT_GRAPH: `${serverUrl}/api/container_vision/get_train_report_graph/`,
        GET_TRAIN_WISE_CONTAINER_GRAPH: `${serverUrl}/api/container_vision/get_train_wise_container_graph/`,
        GET_TRAIN_WISE_CONTAINER_RATIO_GRAPH: `${serverUrl}/api/container_vision/get_train_wise_container_ratio_graph/`,
        GET_CONTAINERS_BY_TIME_GRAPH: `${serverUrl}/api/container_vision/get_container_count_by_time/`,
        TESTDATA: `${serverUrl}/api/container_vision/getTestData/`,
        TESTTRAINDATA: `${serverUrl}/api/container_vision/getTestTrainData/`,
        REGENERATE_REPORT: `${serverUrl}/api/container_vision/regenerate_report/`,
        APPROVE_REPORT:`${serverUrl}/api/container_vision/approve_report/`,
        EDIT_REMARKS:`${serverUrl}/api/container_vision/edit_remarks/`,
        MERGE_CONTAINER:`${serverUrl}/api/container_vision/merge_containers/`,
    }
}
