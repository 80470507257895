import React, { useState } from "react";
import { Button } from "../../../../../components/button";
import { EditIcon, InfoIcon } from "lucide-react";
import { Input, message, Modal, Popover } from "antd";
import axios from "axios";
import { APIStandards } from "../../../../../lib/api_standards";

type Props = {};

function EditRemarks({ instance }: any) {
  const [modal1Open, setModal1Open] = useState(false);
  const [remarks, setRemarks] = useState(instance["remarks"]);
  const [updatedRemarks, setUpdatedRemarks] = useState(instance["remarks"]);
  const editRemarks = async () => {
    const res = await axios.get(
      `${APIStandards.APP.EDIT_REMARKS}?instance_id=${instance.container_id}&remarks=${remarks}`
    );
    const data = res.data;
    const status = data["success"];
    if (status) {
      message.success("Remarks Updated");
      setUpdatedRemarks(remarks);
    } else {
      if (data["error"]) {
        message.error(data["error"].toString());
      } else {
        message.error("Something went wrong");
      }
    }
  };
  return (
    <div>
      <Modal
        title="Edit Remarks"
        centered
        open={modal1Open}
        onOk={async () => {
          await editRemarks();
          setModal1Open(false);
        }}
        onCancel={() => setModal1Open(false)}
      >
        <Input
          placeholder="Remarks"
          defaultValue={remarks}
          onChange={(value) => {
            setRemarks(value.target.value);
          }}
        />
      </Modal>
      <div>
        <Popover
          placement="topLeft"
          className="flex items-center justify-center mx-auto"
          title={
            JSON.parse(sessionStorage.getItem("activeUser") || "{}") &&
            JSON.parse(sessionStorage.getItem("activeUser") || "{}").role ===
              "admin" ? (
              <div className="flex flex-row justify-between">
                <div>Remarks</div>
                <div>
                  <Button onClick={() => setModal1Open(true)}>
                    <EditIcon />
                  </Button>
                </div>
              </div>
            ) : (
              "Remarks"
            )
          }
          content={<p>{updatedRemarks ? updatedRemarks : "No remarks"}</p>}
        >
          <InfoIcon />
        </Popover>
      </div>
    </div>
  );
}

export default EditRemarks;
