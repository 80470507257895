import { Button, message } from "antd";
import axios from "axios";
import { Ban, Check } from "lucide-react";
import React, { useState } from "react";
import { APIStandards } from "../../../../../lib/api_standards";

type Props = {};

function ApproveButton({ instance }: any) {
  const [updatedStatus, setUpdatedStatus] = useState(instance.isApproved);
  const approve = async (a_status: boolean) => {
    const res = await axios.get(
      `${APIStandards.APP.APPROVE_REPORT}?instance_id=${instance.container_id}&status=${a_status}`
    );
    const data = res.data;
    const status = data["success"];
    if (status) {
      message.success(a_status ? "Approved" : "Disabled");

      setUpdatedStatus(a_status);
    } else {
      if (data["error"]) {
        message.error(data["error"].toString());
      } else {
        message.error("Something went wrong");
      }
    }
  };
  return (
    <div>
      {updatedStatus ? (
        <Button
          icon={<Ban />}
          className=" bg-red-600 text-white"
          onClick={() => {
            approve(false);
          }}
        >
          Disable
        </Button>
      ) : (
        <Button
          icon={<Check />}
          className=" bg-green-600 text-white"
          onClick={() => {
            approve(true);
          }}
        >
          Approve
        </Button>
      )}
    </div>
  );
}

export default ApproveButton;
