"use client";

import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../../components/table_custom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the CSS for the date picker
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Input } from "../../../../../../components/input";
import { Button } from "../../../../../../components/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../../../../components/drop_down_menu";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  setSelectedDate?: any;
  selectedDate?: any;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  setSelectedDate,
  selectedDate,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [userDetails, setUserDetails] = useState(
    JSON.parse(sessionStorage.getItem("activeUser") || "{}")
  );

  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    if (userDetails.role === "admin" || userDetails.role === "dev") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [userDetails]);
  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  });
  useEffect(() => {
    table.setPageSize(6);
  }, []);
  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setShowCalendar(false); // Hide calendar after selecting a date
  };
  return (
    <div>
      {/* Inline styles for react-datepicker internal elements */}
      <style>
        {`
          /* Styling the entire calendar */
          .custom-calendar {
            width: 440px !important;  /* Increase width of calendar */
            height: 380px !important; /* Increase height of calendar */
            font-size: 1.2rem !important; /* Adjust font size for calendar */
            padding: 3px !important;  /* Padding around the calendar */
          }

          /* Styling the individual day cells */
          .custom-calendar .react-datepicker__day {
            width: 55px !important;  /* Increase width of day cells */
            height: 46px !important; /* Increase height of day cells */
            font-size: 1.3rem !important; /* Increase the font size of day numbers */
            line-height: 20px !important; /* Vertically center the text */
          }

          /* Styling the month name */
          .custom-calendar .react-datepicker__month {
            font-size: 1.6rem !important; /* Increase font size for month */
            line-height: 1.5 rem !important; /* Adjust line height for readability */
          }

          /* Optional: Styling the Today button */
          .custom-calendar .react-datepicker__today-button {
            font-size: 1rem !important; /* Increase font size for Today button */
            padding: 5px 5px !important; /* Add padding around Today button */
          }
        `}
      </style>

      <div className="flex items-center py-4">
        <Input
          placeholder="Filter instances..."
          value={
            (table.getColumn("container_id")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("container_id")?.setFilterValue(event.target.value)
          }
          className="w-full mr-2"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value: any) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
        <Button
          className="ml-2"
          variant={"default"}
          onClick={() => {
            setShowCalendar(!showCalendar);
          }}
        >
          {" "}
          {selectedDate
            ? `Selected Date: ${format(selectedDate, "yyyy-MM-dd")}`
            : "Select Date"}
        </Button>
        {showCalendar && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            {/* Overlay to close the popup when clicking outside */}
            <div
              className="absolute inset-0 bg-black opacity-50"
              onClick={() => setShowCalendar(false)}
            ></div>

            {/* Calendar container */}
            <div className="relative bg-white p-4 rounded-lg shadow-lg z-10">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd" // Ensure correct format in picker
                inline={true}
                todayButton="Today" // Button to quickly select today
                className="text-center"
                calendarClassName="custom-calendar"
                minDate={
                  isAdmin ? new Date(2024, 10, 1) : new Date(2024, 11, 18)
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="rounded-md border">
        <Table>
          {/* <TableHeader className="bg-[#0A4378] hover:bg-[#0A4378]">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-white">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader> */}
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup, index) => (
              <TableRow
                key={headerGroup.id}
                className={
                  index === 0
                    ? "bg-[#0A4378] hover:bg-[#0A4378]"
                    : "bg-secondary"
                }
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`text-center ${
                        index === 0
                          ? "text-primary-foreground font-bold"
                          : "text-secondary-foreground"
                      }`}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      {/* <div className="absolute bottom-0 right-[2%] items-end justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div> */}

      <div className="absolute bottom-[15%] right-[1%] items-end justify-end space-x-2 py-2">
        <Button
          variant="outline"
          size="lg"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="border-gray-700 hover:border-gray-800 focus:ring-2 focus:ring-gray-500"
        >
          PREVIOUS
        </Button>

        <Button
          variant="outline"
          size="lg"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="border-gray-800 hover:border-gray-800 focus:ring-2 focus:ring-gray-500"
        >
          NEXT
        </Button>
      </div>

      {/* <div className="absolute bottom-0 right-[40%] items-center justify-center space-x-2 py-4">
        <footer
          style={{
            textAlign: "center",
            padding: "px",
            marginTop: "600px",
            // marginBottom: '0px',
            // marginRight: '80px',
            backgroundColor: "#ffffff",
            color: "#333333",
            position: "relative",
            bottom: "10px",
            width: "100%",
            fontSize: "14px",
          }}
        >
          <p>
            Copyrights &copy; {new Date().getFullYear()} RoshAi. All Rights
            Reserved.
          </p>
        </footer>
      </div> */}
    </div>
  );
}
